import { useRailsContext } from "@/components"

export const useExtractInfoFromSsotUrl = () => {
  const { featureFlags } = useRailsContext()

  const splittedPathname = window.location.pathname.split("/")

  if (!splittedPathname.includes("ssot_documents")) {
    return {
      oysterScore: undefined,
      section: undefined,
      category: undefined,
    }
  }

  if (splittedPathname[splittedPathname.length - 1] === "edit") {
    splittedPathname.pop()
  }

  const oysterScore = featureFlags.oysterScoreSections
    ? splittedPathname[splittedPathname.length - 1]
    : undefined

  const getPositionInArray = (number: number) =>
    splittedPathname.length - (featureFlags.oysterScoreSections ? number : number - 1)

  const section = splittedPathname[getPositionInArray(2)]
  const category = splittedPathname[getPositionInArray(3)]

  return {
    oysterScore,
    section,
    category,
  }
}
