import React from "react"
import { Editor } from "@tinymce/tinymce-react"
import { Editor as EditorType } from "tinymce"
import TestEditor from "./TestEditor"
import { API_KEY } from "./tinymce"
import { useTinyCommentsCallbacks } from "./useTinyCommentsCallbacks"
import {
  BeforeCommentsThreadCreated,
  BeforeCommentsThreadDeleted,
  OnCommentsThreadCreated,
  OnCommentsThreadDeleted,
} from "./useComments"
import { useSSOTDocumentContext, useSSOTSectionsContext } from "@administrate/views/components"
import { useRailsContext } from "@/components"
import { pickFile, removeStyleAndClass } from "@sageEditor/utils"
import { customlink } from "@sageEditor/plugins"

interface RichEditorProps {
  readOnly?: boolean
  onChange?: (value: string, editor: EditorType) => void
  onInit?: (value: string) => void
  onCommentsThreadCreated?: OnCommentsThreadCreated
  onCommentsThreadDeleted?: OnCommentsThreadDeleted
  beforeCommentsThreadCreated?: BeforeCommentsThreadCreated
  beforeCommentsThreadDeleted?: BeforeCommentsThreadDeleted
}

const RichEditor: React.FC<RichEditorProps> = ({
  readOnly,
  onChange,
  onCommentsThreadCreated,
  onCommentsThreadDeleted,
  onInit,
  beforeCommentsThreadCreated,
  beforeCommentsThreadDeleted,
}: RichEditorProps) => {
  const {
    state: { skinUrl },
  } = useSSOTDocumentContext()
  const { activeSection } = useSSOTSectionsContext()

  const fieldId = activeSection?.id.toString()
  const commentFunctions = useTinyCommentsCallbacks({
    ssotSectionId: activeSection?.id,
    onCommentsThreadCreated,
    onCommentsThreadDeleted,
    beforeCommentsThreadCreated,
    beforeCommentsThreadDeleted,
  })
  const { railsEnv, useEditorAPIKey } = useRailsContext()

  const onEditorChange = (value, editor) => {
    if (readOnly) {
      editor.isDirty() && onChange?.(value, editor)
    } else {
      onChange?.(value, editor)
    }
  }

  if (railsEnv == null || (railsEnv === "test" && !useEditorAPIKey)) {
    return (
      <TestEditor
        id={fieldId}
        textareaName={fieldId}
        initialValue={activeSection?.content}
        onEditorChange={onEditorChange}
      />
    )
  }

  return (
    <div className="relative">
      <Editor
        apiKey={API_KEY}
        id={fieldId}
        textareaName={fieldId}
        initialValue={activeSection?.content}
        onEditorChange={onEditorChange}
        onInit={(_, editor) => onInit?.(editor.startContent)}
        init={{
          skin_url: skinUrl,
          theme: "silver",
          min_height: 650,
          max_height: 750,
          menubar: false,
          statusbar: !readOnly,
          sidebar_show: !readOnly && !!activeSection?.content && "showcomments",
          plugins: readOnly
            ? ["autoresize", "tinycomments"]
            : [
                "advlist",
                "anchor",
                "autolink",
                "charmap",
                "code",
                "footnotes",
                "fullscreen",
                "help",
                "image",
                "insertdatetime",
                "link",
                "lists",
                "media",
                "paste",
                "preview",
                "searchreplace",
                "table",
                "tinycomments",
                "visualblocks",
                "wordcount",
              ],
          paste_preprocess: function (_plugin, args) {
            args.content = removeStyleAndClass(args.content)
          },
          image_caption: true,
          image_advtab: true,
          image_title: true,
          file_picker_callback: pickFile,
          toolbar: readOnly
            ? ["showcomments"]
            : [
                "undo redo | blocks | bold italic strikethrough customlink forecolor backcolor | " +
                  "alignleft aligncenter alignright alignjustify | " +
                  "removeformat | help",
                "bullist numlist | outdent indent | image | " +
                  "showcomments | fullscreen | " +
                  "statustag-legalpsreview statustag-kmhandover | " +
                  "footnotes footnotesupdate",
                "table tableprops tablecellprops tableinsertrowbefore tableinsertrowafter tabledeleterow tablerowprops tablecutrow tablecopyrow tablepasterowbefore tablepasterowafter tableinsertcolbefore tableinsertcolafter tabledeletecol",
              ],
          content_style: `body { font-family:Helvetica,Arial,sans-serif; ${
            readOnly && "margin:0; padding:0;"
          } }`,
          setup: function (editor) {
            customlink(editor)
          },
          tinycomments_mode: "callback",
          contextmenu: "statustag-legalpsreview statustag-kmhandover",
          ...commentFunctions,
          init_instance_callback: editor => {
            // this is needed because the `disabled` option from the `Editor` also disable
            // the toolbar options, but in this case we need to have it enabled to allow comments
            if (readOnly) editor.getBody().setAttribute("contenteditable", "false")

            // Links couldn't be opened from the editor, this is to fix that
            editor.on("click", function ({ target }) {
              if (target.href && readOnly) {
                // This fixes internal links
                if (target.dataset.mceHref.startsWith("#")) {
                  editor.dom.doc
                    .querySelector(target.dataset.mceHref)
                    .scrollIntoView({ behavior: "smooth" })

                  // This fixes external links
                } else {
                  window.open(target.href, "_blank")
                }
              }
            })
          },
        }}
      />
    </div>
  )
}

export default RichEditor
