import React, { useState } from "react"
import { useIntl } from "react-intl"

import {
  Button,
  FormLabel,
  LoadingSpinner,
  Toast,
  UpdateAndPublicationInfo,
  useToast,
} from "@/components"

import RichEditor from "@sageEditor/RichEditor"
import { LegalAndPeopleServicesReviewStatus, SSOTDocument, SSOTSection } from "@/types/SSOTDocument"
import { useSSOTSectionAPI } from "@/api/useSSOTSectionAPI"
import {
  BeforeCommentsActionParams,
  BeforeCommentsThreadCreatedParams,
} from "@sageEditor/useComments"
import { getSelectedText } from "../../utils/getSelectedText"
import {
  ContentOwner,
  OUTDATE_CONTENT_MODAL_TYPE,
  OutdatedContentModal,
  SSOTLayout,
  Status,
  SSOTDocumentProvider,
  SSOTSectionsProvider,
  useSSOTDocumentContext,
  useSSOTSectionsContext,
} from "../components"
import { ShowSSOTDecisionTable } from "./ShowSSOTDecisionTable"
import { AdministrateActions } from "@/types/commons"
import { ConfirmPublishModal } from "./ConfirmPublishModal"
import { useShowSSOTContentUpdatedToast } from "@administrate/views/hooks/useShowSSOTContentUpdatedToast"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"
import { useExtractInfoFromSsotUrl } from "@administrate/views/hooks/useExtractInfoFromSsotUrl"

const magnifyingGlass = require("./assets/magnifying-glass.svg")
const eye = require("./assets/eye.svg")

interface SSOTDocumentShowProps {
  actions: AdministrateActions
  ssotDocument: SSOTDocument
  skinUrl: string
}

export const SSOTDocumentShowInner = () => {
  const {
    state: { ssotDocument, actions, editorContent },
    dispatch,
  } = useSSOTDocumentContext()
  const toast = useToast()
  const { activeSection, setActiveSection, updateSectionContent } = useSSOTSectionsContext()
  const { oysterScore, section, category } = useExtractInfoFromSsotUrl()
  const { urls } = ssotDocument
  const { preview, edit, publish } = actions

  const { $t } = useIntl()
  const [showModal, setShowModal] = useState<"outdated-content" | "confirm-publish">()
  const [comment, setComment] = useState<string>()
  const [sectionSelectLoading, setSectionSelectLoading] = useState(false)

  const { updateSSOTSection, commentSSOTSection } = useSSOTSectionAPI()

  useShowSSOTContentUpdatedToast()

  const onSSOTSectionSuccessfulResponse = async (response: Response) => {
    const res = (await response.json()) as SSOTSection
    updateSectionContent({
      id: res.id,
      newContent: res.content,
      newStatus: res.lpsReviewStatus,
      newLockVersion: res.lockVersion,
      newUpdatedAt: res.updatedAt,
      newUpdatedBy: res.updatedBy,
    })
    setActiveSection(res)
    setComment(undefined)
  }

  const updateSection = ({
    status,
    content,
  }: {
    status?: LegalAndPeopleServicesReviewStatus
    content?: string
  }) =>
    updateSSOTSection({
      id: activeSection.id,
      content: content || activeSection.content,
      lockVersion: activeSection.lockVersion,
      lpsReviewStatus: status || activeSection.lpsReviewStatus,
      onResponse: async (response: Response) => {
        if (!response.ok) {
          if (response.status === 409) {
            setShowModal("outdated-content")
          } else {
            const { content } = (await response.json()) as { content: string[] }

            content?.map(errorMessage =>
              toast({
                render: (
                  <Toast variation="error">
                    <Toast.Content>{errorMessage}</Toast.Content>
                  </Toast>
                ),
              }),
            )
          }
          return
        }

        onSSOTSectionSuccessfulResponse(response)
      },
    })

  const commentSection = ({ content }: { content: string }) =>
    commentSSOTSection({
      id: activeSection.id,
      content: content || activeSection.content,
      lockVersion: activeSection.lockVersion,
      onResponse: async (response: Response) => {
        if (!response.ok) {
          return setShowModal("outdated-content")
        }

        onSSOTSectionSuccessfulResponse(response)
      },
    })

  const handleCreateCommentsThread = ({
    execute,
    cancel,
    req,
  }: BeforeCommentsThreadCreatedParams) => {
    // Prevent creating a comments thread over a whole section or without text selected.
    // Creating a thread over a whole section led to bugs.
    if (getSelectedText(activeSection.id.toString()).length === 0) {
      return cancel("No text selected")
    }

    setComment(req.content)
    execute()
  }

  const ButtonWrapper = ({ children }) => <div className="mr-2 mb-2">{children}</div>

  const PreviewButton = () => (
    <ButtonWrapper>
      <Button
        variant="secondary"
        onClick={() =>
          amplitudeLogEvent("[SE]CA-click_preview_ssot_document", {
            countryCode: ssotDocument.country.code,
            category,
            section,
            oysterScore,
          })
        }
        href={urls.app.preview}
        target="_blank"
      >
        <img src={magnifyingGlass} alt="Magnifying glass" className="mr-2" />
        {$t({ id: "administrate.actions.preview" })}
      </Button>
    </ButtonWrapper>
  )

  const EditButton = () => (
    <ButtonWrapper>
      <Button
        variant="primary"
        href={activeSection.urls.app.edit}
        onClick={() => {
          amplitudeLogEvent("[SE]-CA-click-edit-ssot-section", {
            countryCode: ssotDocument.country.code,
            category,
            section,
            oysterScore,
          })
        }}
      >
        {$t({ id: "administrate.actions.edit" })}
      </Button>
    </ButtonWrapper>
  )

  const PublishButton = () => (
    <ButtonWrapper>
      <Button
        variant="secondary"
        onClick={() => {
          amplitudeLogEvent("[SE]CA-click_publish_ssot_document_to_zendesk")
          setShowModal("confirm-publish")
        }}
      >
        {$t({ id: "administrate.actions.ssot_documents.publish" })}
      </Button>
    </ButtonWrapper>
  )

  const ViewOnZendeskButton = () => (
    <ButtonWrapper>
      <Button variant="secondary" href={activeSection.urls.zendeskArticle} target="_blank">
        {$t({ id: "administrate.actions.ssot_documents.view_on_zendesk" })}
      </Button>
    </ButtonWrapper>
  )

  if (!activeSection) {
    return <LoadingSpinner />
  }

  return (
    <SSOTLayout
      onActiveSectionChange={setActiveSection}
      documentStatusEditable={edit}
      onBackClicked={() => (window.location.href = "/app/ssot_documents")}
    >
      <div className="w-full ml-3">
        <div className="flex justify-between items-center mr-5 mb-6">
          <div className="flex items-center">
            <h2 className="text-xl font-semibold">{activeSection.name}</h2>
            {!edit && (
              <div className="flex items-center ml-4 border-2 px-3 py-1 rounded-full text-gray-400 text-sm">
                <img src={eye} alt="View only mode" />
                <p className="ml-1">View only</p>
              </div>
            )}
          </div>

          <div className="flex flex-wrap">
            {preview && <PreviewButton />}
            {edit && <EditButton />}

            {activeSection.urls.zendeskArticle && (
              <>
                {publish && <PublishButton />}
                <ViewOnZendeskButton />
              </>
            )}
          </div>
        </div>

        <section>
          <div className="w-full mb-8">
            {/* uncomment to view object structure */}
            {/* <pre>{JSON.stringify(activeSection, null, 2)}</pre> */}

            <div className="mb-4 flex items-center">
              <div className="flex items-center">
                <FormLabel
                  htmlFor="section-status"
                  id="label-section-status"
                  className="mr-2"
                  label="Status"
                />
                <Status
                  editable={edit}
                  value={activeSection.lpsReviewStatus}
                  loading={sectionSelectLoading}
                  onChange={(status: LegalAndPeopleServicesReviewStatus) => {
                    setSectionSelectLoading(true)
                    updateSection({ status })
                    toast({
                      render: (
                        <Toast variation="success">
                          <Toast.Content>
                            {$t({ id: "alerts.ssot_documents.section_status_updated" })}
                          </Toast.Content>
                        </Toast>
                      ),
                    })
                    setActiveSection({ ...activeSection, lpsReviewStatus: status })
                    setSectionSelectLoading(false)
                  }}
                  id="section-status"
                />
              </div>
              <div className="ml-8">
                <ContentOwner />
              </div>
            </div>

            <UpdateAndPublicationInfo
              updatedAt={activeSection.updatedAt}
              publishedAt={activeSection.publishedAt}
              updatedBy={activeSection.updatedBy}
              publishedBy={activeSection.publishedBy}
            />
            {/* key avoids reusing iframe id, seems like bug in tinymce-react */}
            {activeSection.content ? (
              <RichEditor
                key={activeSection.id}
                readOnly
                onChange={content => {
                  if (content !== editorContent) {
                    commentSection({ content })
                    dispatch({ editorContent: content })
                  }
                }}
                onInit={content => dispatch({ editorContent: content })}
                beforeCommentsThreadCreated={handleCreateCommentsThread}
                beforeCommentsThreadDeleted={({ execute }: BeforeCommentsActionParams) => {
                  setComment(OUTDATE_CONTENT_MODAL_TYPE.DELETE)
                  execute()
                }}
              />
            ) : (
              <div className="mb-4 flex items-center justify-center">
                <p className="text-gray-400">This section is empty.</p>
              </div>
            )}
          </div>

          {activeSection.decisionTables[0] && <ShowSSOTDecisionTable />}
        </section>

        <OutdatedContentModal
          modalOpen={showModal === "outdated-content"}
          onClose={() => setShowModal(undefined)}
          comment={comment}
        />
        <ConfirmPublishModal
          modalOpen={showModal === "confirm-publish"}
          onClose={() => setShowModal(undefined)}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </div>
    </SSOTLayout>
  )
}

export const SSOTDocumentShow: React.FC<SSOTDocumentShowProps> = props => (
  <SSOTDocumentProvider initialState={{ ...props, editMode: false }}>
    <SSOTSectionsProvider rootSectionChildren={props.ssotDocument.rootSection.children}>
      <SSOTDocumentShowInner />
    </SSOTSectionsProvider>
  </SSOTDocumentProvider>
)
