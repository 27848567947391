import React, { useEffect, useState } from "react"

import {
  Button,
  FormLabel,
  LoadingSpinner,
  DecisionTableEditor,
  useToast,
  Toast,
} from "@/components"
import RichEditor from "@sageEditor/RichEditor"
import { SSOTDocument, SSOTSection } from "@/types/SSOTDocument"
import { useSSOTSectionAPI } from "@/api/useSSOTSectionAPI"
import { UnsavedChangesModal } from "./UnsavedChangesModal"
import { ConfirmUpdateOnThreadCreationModal } from "./ConfirmUpdateOnThreadCreationModal"
import {
  ConfirmUpdateOnThreadCreationModalOpen,
  ConfirmUpdateOnThreadCreationModalType,
  UnsavedChangeModalTypes,
} from "./types"
import { BeforeCommentsActionParams } from "@sageEditor/useComments"
import { getSelectedText } from "@administrate/utils/getSelectedText"
import {
  ContentOwner,
  OUTDATE_CONTENT_MODAL_TYPE,
  OutdatedContentModal,
  Owner,
  SSOTLayout,
  Status,
  SSOTDocumentProvider,
  SSOTSectionsProvider,
  useSSOTDocumentContext,
  useSSOTSectionsContext,
} from "../components"
import { ConfirmUpdateModal } from "./ConfirmUpdateModal"
import { AdministrateActions } from "@/types/commons"
import { EditRuleOwnership } from "./EditRuleOwnership"
import { FetchMethod, doFetch } from "@/api/doFetch"
import { useShowSSOTContentUpdatedToast } from "@administrate/views/hooks/useShowSSOTContentUpdatedToast"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"
import { useExtractInfoFromSsotUrl } from "@administrate/views/hooks/useExtractInfoFromSsotUrl"

interface SSOTDocumentEditProps {
  actions: AdministrateActions
  ssotDocument: SSOTDocument
  skinUrl: string
}

export const SSOTDocumentEditInner = () => {
  const {
    state: { ssotDocument, editorContent },
    dispatch,
  } = useSSOTDocumentContext()
  const { country, subdivision } = ssotDocument

  const { activeSection, setActiveSection, updateSectionContent, updateDecisionTable } =
    useSSOTSectionsContext()
  const { oysterScore, section, category } = useExtractInfoFromSsotUrl()
  const { setContentJustUpdated } = useShowSSOTContentUpdatedToast()
  const toast = useToast()

  const [isInitializing, setIsInitializing] = useState(true)
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState<
    SSOTSection | UnsavedChangeModalTypes
  >()
  const [confirmUpdateOnThreadCreationModalOpen, setConfirmUpdateOnThreadCreationModalOpen] =
    useState<ConfirmUpdateOnThreadCreationModalOpen>()
  const [contentChanged, setContentChanged] = useState(false)
  const [shouldUpdateSectionOnChange, setShouldUpdateSectionOnChange] = useState(false)
  const [updatingSection, setUpdatingSection] = useState(false)
  const [outdateContentModalOpen, setOutdatedContentModalOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [eligibleOwners, setEligibleOwners] = useState<Owner[]>([])

  const initSection = section => {
    setIsInitializing(true)
    setActiveSection(section)
  }

  const { updateSSOTSection } = useSSOTSectionAPI()

  const updateSection = (done?: () => void, newContent?: string) => {
    setUpdatingSection(true)
    updateSSOTSection({
      id: activeSection.id,
      content: newContent || editorContent,
      lockVersion: activeSection.lockVersion,
      onResponse: async (response: Response) => {
        if (!response.ok) {
          if (response.status === 409) {
            setOutdatedContentModalOpen(true)
          } else {
            const { content } = (await response.json()) as { content: string[] }

            content?.map(errorMessage =>
              toast({
                render: (
                  <Toast variation="error">
                    <Toast.Content>{errorMessage}</Toast.Content>
                  </Toast>
                ),
              }),
            )
          }
          return setUpdatingSection(false)
        }

        const res = (await response.json()) as SSOTSection
        updateSectionContent({
          id: res.id,
          newContent: res.content,
          newStatus: res.lpsReviewStatus,
          newLockVersion: res.lockVersion,
          newUpdatedAt: res.updatedAt,
          newUpdatedBy: res.updatedBy,
        })
        setActiveSection(res)
        setContentChanged(false)
        setShouldUpdateSectionOnChange(false)
        done?.()
        setUpdatingSection(false)
      },
    })
  }

  useEffect(() => {
    doFetch({
      endpoint: "users/eligible_content_owners",
      method: FetchMethod.GET,
      done: async (res: { users: Owner[] }) => {
        setEligibleOwners(
          res.users.sort(({ name: nameA }, { name: nameB }) => {
            if (nameA.toLowerCase() < nameB.toLowerCase()) {
              return -1
            }
            return 1
          }),
        )
      },
    })
  }, [])

  const handleSetActiveSection = (section: SSOTSection) => {
    if (contentChanged) {
      setUnsavedChangesModalOpen(section)
    } else {
      initSection(section)
    }
  }

  const handleCancel = () => {
    if (contentChanged) {
      setUnsavedChangesModalOpen(UnsavedChangeModalTypes.Cancel)
    } else {
      window.location.href = showHrefWithSection
    }
  }

  const handleUpdate = () => {
    amplitudeLogEvent("[SE]-CA-click-update-ssot-section", {
      countryCode: ssotDocument.country.code,
      category,
      section,
      oysterScore,
    })

    if (activeSection.decisionTables?.length > 0) {
      return setUpdateModalOpen(true)
    }
    updateSection(() => {
      setContentJustUpdated()
      window.location.href = showHrefWithSection
    })
  }

  const handleCloseUnsavedChangesModal = () => {
    if (unsavedChangesModalOpen === UnsavedChangeModalTypes.Back) {
      return (window.location.href = "/app/ssot_documents")
    }
    if (unsavedChangesModalOpen === UnsavedChangeModalTypes.Cancel) {
      return (window.location.href = showHrefWithSection)
    }
    initSection(unsavedChangesModalOpen)
    setUnsavedChangesModalOpen(undefined)
  }

  const handleThreadExistence =
    (type: ConfirmUpdateOnThreadCreationModalType) =>
    ({ execute, cancel }: BeforeCommentsActionParams) => {
      // Prevent creating a comments thread over a whole section or without text selected.
      // Creating a thread over a whole section led to bugs.
      if (
        getSelectedText(activeSection.id.toString()).length === 0 &&
        type === ConfirmUpdateOnThreadCreationModalType.Create
      ) {
        return cancel("No text selected")
      }

      setConfirmUpdateOnThreadCreationModalOpen({
        updateAction: execute,
        cancelAction: cancel,
        type,
      })
      setShouldUpdateSectionOnChange(true)
    }

  if (!activeSection) {
    return <LoadingSpinner />
  }

  const showHrefWithSection = activeSection.urls.app.resource

  return (
    <SSOTLayout
      onActiveSectionChange={handleSetActiveSection}
      onBackClicked={() => {
        if (contentChanged) {
          return setUnsavedChangesModalOpen(UnsavedChangeModalTypes.Back)
        }

        window.location.href = "/app/ssot_documents"
      }}
    >
      <div className="w-full">
        <section>
          <div className="w-full mb-8">
            <div>
              <h2 className="text-2xl font-semibold mb-4">{activeSection.name}</h2>
            </div>
            <div className="flex items-center mb-8">
              <div className="flex items-center">
                <FormLabel
                  htmlFor="section-status"
                  id="label-section-status"
                  className="mr-2"
                  label="Status"
                />
                <Status
                  editable={false}
                  value={activeSection.lpsReviewStatus}
                  id="section-status"
                />
              </div>
              <div className="ml-8">
                <ContentOwner editable eligibleOwners={eligibleOwners} />
              </div>
            </div>

            {/* key avoids reusing iframe id, seems like bug in tinymce-react */}
            <RichEditor
              key={activeSection.id}
              onChange={content => {
                if (isInitializing) return

                setContentChanged(editorContent !== content)
                dispatch({ editorContent: content })
                if (shouldUpdateSectionOnChange) {
                  updateSection(undefined, content)
                }
              }}
              onInit={content => {
                dispatch({ editorContent: content })
                setContentChanged(false)
                setIsInitializing(false)
              }}
              beforeCommentsThreadCreated={handleThreadExistence(
                ConfirmUpdateOnThreadCreationModalType.Create,
              )}
              beforeCommentsThreadDeleted={handleThreadExistence(
                ConfirmUpdateOnThreadCreationModalType.Delete,
              )}
            />
          </div>

          <div className="flex">
            <div className="mr-4">
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            <div>
              <Button variant="primary" onClick={handleUpdate} loading={updatingSection}>
                Update
              </Button>
            </div>
          </div>

          {activeSection.decisionTables[0] && (
            <div className="my-12">
              <h3 className="text-xl mb-4 font-semibold text-black">Country rules</h3>
              <div className="mb-8">
                <EditRuleOwnership eligibleOwners={eligibleOwners} />
              </div>
              <div
                key={activeSection.decisionTables[0].id}
                className="mb-2"
                style={{ maxWidth: "calc(100vw - 25rem)" }}
              >
                <DecisionTableEditor
                  actions={{
                    create: true,
                    index: true,
                    show: true,
                    update: true,
                    edit: true,
                    new: true,
                    preview: true,
                    publish: true,
                  }}
                  decisionTable={activeSection.decisionTables[0]}
                  insideSSOT={
                    country
                      ? {
                          countryCode: country?.code,
                          subdivisionCode: subdivision?.code,
                          editMode: true,
                          activeSection,
                          updateDecisionTable,
                        }
                      : undefined
                  }
                />
              </div>
            </div>
          )}
        </section>
      </div>

      <UnsavedChangesModal
        modalOpen={!!unsavedChangesModalOpen}
        setModalOpen={() => setUnsavedChangesModalOpen(undefined)}
        onModalClose={handleCloseUnsavedChangesModal}
        updateSection={updateSection}
        updatingSection={updatingSection}
        setContentJustUpdated={setContentJustUpdated}
      />
      <ConfirmUpdateOnThreadCreationModal
        modalOpen={confirmUpdateOnThreadCreationModalOpen}
        setModalOpen={setConfirmUpdateOnThreadCreationModalOpen}
        setShouldUpdateSectionOnChange={setShouldUpdateSectionOnChange}
      />
      <OutdatedContentModal
        comment={OUTDATE_CONTENT_MODAL_TYPE.EDIT}
        onClose={() => setOutdatedContentModalOpen(false)}
        modalOpen={outdateContentModalOpen}
      />
      {/* This one is conditionally rendered to reset the state inside it every time it's closed */}
      {updateModalOpen && (
        <ConfirmUpdateModal
          modalOpen={updateModalOpen}
          setModalOpen={setUpdateModalOpen}
          updateSection={updateSection}
          updatingSection={updatingSection}
          redirectionUrl={showHrefWithSection}
          setContentJustUpdated={setContentJustUpdated}
        />
      )}
    </SSOTLayout>
  )
}

export const SSOTDocumentEdit: React.FC<SSOTDocumentEditProps> = props => (
  <SSOTDocumentProvider initialState={{ ...props, editMode: true }}>
    <SSOTSectionsProvider rootSectionChildren={props.ssotDocument.rootSection.children}>
      <SSOTDocumentEditInner />
    </SSOTSectionsProvider>
  </SSOTDocumentProvider>
)
